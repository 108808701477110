

import * as Card from "../../toolkit/Card.bs.js";
import * as Head from "../../components/head/Head.bs.js";
import * as I18n from "../../i18n/I18n.bs.js";
import * as $$Text from "../../toolkit/Text.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Route from "../../Route.bs.js";
import * as Stack from "../../toolkit/Stack.bs.js";
import * as React from "react";
import * as Button from "../../toolkit/Button.bs.js";
import * as Layout from "../../layout/Layout.bs.js";
import * as TextInput from "../../components/mui/TextInput.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as LimitedWidthContainer from "../../toolkit/LimitedWidthContainer.bs.js";

var code = Route.toCode(/* ListRandomizer */4);

function tr(translationPath) {
  return I18n.t(code + ("." + translationPath));
}

function ListRandomizer$Generator(Props) {
  var generateButtonText = tr("body.generate_button_text");
  var result = tr("body.result");
  var hintText = tr("body.hint");
  var textBefore = tr("body.before");
  var textAfter = tr("body.after");
  var match = React.useState((function () {
          return "";
        }));
  var setText = match[1];
  var text = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setRandomizedText = match$1[1];
  var randomizedText = match$1[0];
  var onClickRandomize = function ($$event) {
    $$event.preventDefault();
    var newlinesRegex = /\n/g;
    var result = Belt_Array.shuffle(Belt_Array.keep(Belt_Array.map(text.split(newlinesRegex), (function (mbString) {
                      return Belt_Option.getWithDefault(mbString, "");
                    })), (function (str) {
                  return str !== "";
                }))).join("\n");
    return Curry._1(setRandomizedText, (function (param) {
                  return result;
                }));
  };
  return React.createElement(LimitedWidthContainer.make, {
              maxWidth: /* Px840 */2,
              fullWidthMobile: true,
              children: React.createElement(Stack.make, {
                    gap: /* Lg */3,
                    children: null
                  }, React.createElement(Card.make, {
                        children: null
                      }, React.createElement("p", undefined, hintText), React.createElement(TextInput.make, {
                            type: "number",
                            id: "textBefore",
                            label: textBefore,
                            value: text,
                            onChange: (function (v) {
                                return Curry._1(setText, (function (param) {
                                              return v;
                                            }));
                              }),
                            onBlur: (function (param) {
                                
                              }),
                            multiline: true,
                            rows: "10"
                          })), React.createElement(Button.make, {
                        label: generateButtonText,
                        onClick: onClickRandomize
                      }), React.createElement(Card.make, {
                        children: null
                      }, React.createElement("h5", undefined, result), randomizedText !== undefined ? React.createElement(TextInput.make, {
                              type: "number",
                              id: "textAfter",
                              label: textAfter,
                              value: randomizedText,
                              onChange: (function (param) {
                                  
                                }),
                              onBlur: (function (param) {
                                  
                                }),
                              multiline: true,
                              rowsMax: "20"
                            }) : null))
            });
}

var Generator = {
  Faker: undefined,
  make: ListRandomizer$Generator
};

function ListRandomizer(Props) {
  var pageContext = Props.pageContext;
  var locale = Belt_Option.getWithDefault(pageContext.locale, "en");
  I18n.changeLanguage(locale);
  var title = tr("head.title");
  var description = tr("head.description");
  var h1Text = tr("body.h1");
  var h2Text = tr("body.h2");
  return React.createElement(React.Fragment, undefined, React.createElement(Head.make, {
                  title: title,
                  description: description,
                  lang: locale,
                  route: /* ListRandomizer */4
                }), React.createElement(Layout.make, {
                  route: /* ListRandomizer */4,
                  locale: locale,
                  children: null
                }, React.createElement($$Text.H1.make, {
                      align: /* center */98248149,
                      children: h1Text
                    }), React.createElement($$Text.H2.make, {
                      align: /* center */98248149,
                      children: h2Text
                    }), React.createElement(ListRandomizer$Generator, { })));
}

var route = /* ListRandomizer */4;

var make = ListRandomizer;

var $$default = ListRandomizer;

export {
  route ,
  code ,
  tr ,
  Generator ,
  make ,
  $$default ,
  $$default as default,
  
}
/* code Not a pure module */
